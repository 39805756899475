<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1440px"
    v-if="hasEdit('client-agreements')"
  >
    <template v-slot:activator="{ on }">
      <v-btn v-if="item.id" v-on="on" icon> <v-icon>mdi-pencil</v-icon></v-btn>
      <v-btn v-else text color="primary" v-on="on">Add Contract</v-btn>
    </template>
    <dialog-card
      title="Add New Agreement"
      @close="dialog = false"
      @submit="submit()"
      :loading="loading"
    >
      <please-wait v-if="isLoading" />
      <agreement-editor
        v-else
        v-model="agreement_template"
        :minHeight="minHeight"
      />
    </dialog-card>
  </v-dialog>
</template>

<script>
import { colClientAgreements, functions } from "@/assets/utils/firebase.utils";
import { toTerms } from "@/assets/utils/v2.utils";
import AgreementEditor from "@/components/ui/AgreementEditor.vue";
import DialogCard from "@/components/ui/DialogCard.vue";
import {
  addDoc,
  doc,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import defaultTemplate from "@/assets/constants/default.template";
import {
  formatDateOnly,
  formatFax,
  getDate,
} from "@/assets/utils/common.utils";
import { httpsCallable } from "firebase/functions";
import { orderBy } from "lodash";
import PleaseWait from "@/components/ui/PleaseWait.vue";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { DialogCard, AgreementEditor, PleaseWait },
  data() {
    return {
      dialog: false,
      agreement_template: defaultTemplate,
      loading: false,
      isLoading: true,
    };
  },
  computed: {
    minHeight() {
      return `${window.innerHeight - 300}px`;
    },
  },
  watch: {
    dialog() {
      this.agreement_template = defaultTemplate;
      this.init();
    },
  },
  methods: {
    async submit() {
      try {
        this.loading = true;
        let agreement = formatFax({
          message: this.agreement_template,
          fields: {
            agreement_template: this.agreement_template,
            ...this.currentClient,
            today: formatDateOnly(),
          },
        });
        if (this.item.id) {
          await updateDoc(doc(colClientAgreements, this.item.id), {
            agreement,
            updated_at: getDate(),
          });
          this.$emit("update");
        } else {
          let snap = await addDoc(colClientAgreements, {
            _search: toTerms([this.currentClient.name]),
            agreement,
            agreement_signed_on: "",
            customer_id: this.$route.params.id,
            customer_name: this.currentClient.name,
            digital_signature: "",
            ip_address: "",
            verified_and_agreed_on: false,
            status: "draft",
            created_at: getDate(),
          });
          let sendContract = httpsCallable(functions, "manageAgreement");
          await sendContract({
            id: snap.id,
            type: "send-agreement",
          });
        }
        this.dialog = false;
        this.loading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
    async init() {
      try {
        this.isLoading = true;
        if (this.item.id) {
          if (this.item.agreement) {
            this.agreement_template = this.item.agreement;
          }
          this.isLoading = false;
          return;
        }
        let listQuery = query(
          colClientAgreements,
          orderBy("created_at", "desc"),
          where("customer_id", "==", this.$route.params.id),
          limit(1)
        );
        let template = (await getDocs(listQuery)).docs[0];
        if (template != null) {
          let { agreement } = template.data();
          if (agreement) {
            this.agreement_template = agreement;
          }
        }
        this.isLoading = false;
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>

<style></style>
